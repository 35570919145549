import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import InstarVisionCard from 'components/Software/Windows/InstarVision/Layout/instarVisionCard01';
import NavButtons from 'components/Software/Windows/InstarVision/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "InstarVision v3 for Windows",
  "path": "/Software/Windows/InstarVision/Layout/",
  "dateChanged": "2018-01-25",
  "author": "Mike Polinowski",
  "excerpt": "Set up your video canvas area with one click in the Layout menu. Choose a layout that fits your needs and amount of active cameras. The layout will always reset to its default when you restart the software - the start-up default can be set in the System menu.",
  "image": "../P_SearchThumb_InstarVision_Windows.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Windows.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='InstarVision v3 for Windows' dateChanged='2018-01-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='The InstarVision Surveillance Center is an IP camera software which can support 64 cameras at the same time, including Network cameras, USB cameras remotely accessed secondary installations of the software.' image='/images/Search/P_SearchThumb_InstarVision_Windows.png' twitter='/images/Search/P_SearchThumb_InstarVision_Windows.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/InstarVision/Layout/' locationFR='/fr/Software/Windows/InstarVision/Layout/' crumbLabel="Layout" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3842543c147b81fbda28abe8cc725c05/29114/Banner_en-InstarVision2-0_Layout.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAeElEQVQI143HMQ6CQBSE4XcVOZ+FHUR6ChaqNVZ0ehUaW00g4QLQsuBmWcxvlFDYGCb5MjPC7MAasD24EZ798vFsjdaa6BiDt4h6jGQftf+h6glVub/SypE3L+IkIzzsSe8GkaJFig65GORqll73JgPBqWR3vn3/G+CmwPV440scAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3842543c147b81fbda28abe8cc725c05/e4706/Banner_en-InstarVision2-0_Layout.avif 230w", "/en/static/3842543c147b81fbda28abe8cc725c05/d1af7/Banner_en-InstarVision2-0_Layout.avif 460w", "/en/static/3842543c147b81fbda28abe8cc725c05/7f308/Banner_en-InstarVision2-0_Layout.avif 920w", "/en/static/3842543c147b81fbda28abe8cc725c05/e1c99/Banner_en-InstarVision2-0_Layout.avif 1380w", "/en/static/3842543c147b81fbda28abe8cc725c05/76ea5/Banner_en-InstarVision2-0_Layout.avif 1840w", "/en/static/3842543c147b81fbda28abe8cc725c05/f696c/Banner_en-InstarVision2-0_Layout.avif 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3842543c147b81fbda28abe8cc725c05/a0b58/Banner_en-InstarVision2-0_Layout.webp 230w", "/en/static/3842543c147b81fbda28abe8cc725c05/bc10c/Banner_en-InstarVision2-0_Layout.webp 460w", "/en/static/3842543c147b81fbda28abe8cc725c05/966d8/Banner_en-InstarVision2-0_Layout.webp 920w", "/en/static/3842543c147b81fbda28abe8cc725c05/445df/Banner_en-InstarVision2-0_Layout.webp 1380w", "/en/static/3842543c147b81fbda28abe8cc725c05/78de1/Banner_en-InstarVision2-0_Layout.webp 1840w", "/en/static/3842543c147b81fbda28abe8cc725c05/882b9/Banner_en-InstarVision2-0_Layout.webp 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3842543c147b81fbda28abe8cc725c05/81c8e/Banner_en-InstarVision2-0_Layout.png 230w", "/en/static/3842543c147b81fbda28abe8cc725c05/08a84/Banner_en-InstarVision2-0_Layout.png 460w", "/en/static/3842543c147b81fbda28abe8cc725c05/c0255/Banner_en-InstarVision2-0_Layout.png 920w", "/en/static/3842543c147b81fbda28abe8cc725c05/b1001/Banner_en-InstarVision2-0_Layout.png 1380w", "/en/static/3842543c147b81fbda28abe8cc725c05/161ec/Banner_en-InstarVision2-0_Layout.png 1840w", "/en/static/3842543c147b81fbda28abe8cc725c05/29114/Banner_en-InstarVision2-0_Layout.png 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3842543c147b81fbda28abe8cc725c05/c0255/Banner_en-InstarVision2-0_Layout.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "side-navigation---layout",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#side-navigation---layout",
        "aria-label": "side navigation   layout permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Side Navigation - Layout`}</h2>
    <p>{`Set up your video canvas area with one click in the Layout menu. Choose a layout that fits your needs and amount of active cameras. The layout will always reset to its default when you restart the software - the start-up default can be set in the System menu.`}</p>
    <InstarVisionCard mdxType="InstarVisionCard" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`There are layout templates optimized for a 4:3 aspect ratio for VGA cameras and templates optimized for a 16:9 aspect ratio for HD cameras. Click on the Hamburger Icon (3 horizontal strokes) on top of the Side Navigation to access a list-view that separates these templates for an easy access.`}</p>
    <p><img parentName="p" {...{
        "src": "/en/40df2adf620f6ba17a1716467ab59527/Layout_Viewport_Grid.gif",
        "alt": "Adjust the Layout of your Video Canvas"
      }}></img></p>
    <p>{`You can move your mouse pointer to the edge of the user interface to freely transform it to your personal needs and adapt it to the amount of cameras you want to integrate. You can also close the side menu by clicking on the very thin button between the side menu and the video canvas. Alternatively, simply right-click the video canvas to switch into the video fullscreen mode (unlike the Maximize Button in the top-right of the UI, this will only enlarge the video area and remove all menus. Press Escape on your keyboard to go back the regular view.`}</p>
    <h3 {...{
      "id": "adjust-the-layout-of-your-video-canvas",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#adjust-the-layout-of-your-video-canvas",
        "aria-label": "adjust the layout of your video canvas permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adjust the Layout of your Video Canvas`}</h3>
    <h4 {...{
      "id": "1-x-1-layout",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#1-x-1-layout",
        "aria-label": "1 x 1 layout permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1 x 1 Layout`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/38ce1c00d0a8590a8c59c0801308869f/2bef9/InstarVision2_Layout_1x1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAClklEQVQoz2M4dP7O7lNX9p2/eezq3SNX7p66dvfyrbvXbt+9dffunfv37t6//+DB/cePHt69c/vk8WMnjh09duTwxfPntm/dunbtWoYdx07ffvv57//////9+48X/P3//+fvf99+/Hz68s3lK9euXrnCEBSuF57g29jdUd7c1DN7wdRFyyfMXdo6aX56RUdETnVkdnVEZkV4VnFyUXFudWFKUXZAQrxHZNjBI4efPH7M4OGtb+siZmYrqW8gY+Oha+VhZuxoqmFno+tkp+dkZ+Rqr2dvrWNjYexsZe1pq2FhpW1tbeTocPjY0U+fPjHY2Gla2MjFpugW5DtXV7pkZJqkpJhlZNvFZzgFRDqFRDkEBLu6ezs7B1hYuJvauVt5+FrLaWlfuHTl////DNEJhnoGEnZuss4e6jFxRmHR2rFJ+olphiFRej7hxt6BDlFxEf7hnoFhvuGJHv7Rdrrm+jqmdleuXQdpDoszM7OSM7KQcvBQ9A/VsnVWjIjViU7VdAtTcgqVtg80cfUL0DfRM7YwsXO20rfUCI13Tc5OvHv/HkhzWbtXcolpZKJxYKROVrVpQqFRQKy6S6C8Q5CCW4SiZYCEuKKYoIi4hJyMnIqskoastbOho4/xtRuXQJoL251yek2qZliWTTYq7DeqmGxaPMEov9Mot9Esq94koVrLP0XJO1otItXSP0I/MEEnMNo0ONH81r2rIM0pJZbxlVopjVoJdeoxZZqpjVoFE3QK+vWy2/UzGowy2wzyJqoXTFfK7pMvmKhW3GdcM9m6rNN249ZlJ46fYigsd49KNoqMt/CNMAyIM4rJsojLM8upty5udgxPNw1ONsmqtS3udMpqMSnssCxut89rtClqtZ06s3v5shUA52Ev3ZH/OWEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/38ce1c00d0a8590a8c59c0801308869f/e4706/InstarVision2_Layout_1x1.avif 230w", "/en/static/38ce1c00d0a8590a8c59c0801308869f/d1af7/InstarVision2_Layout_1x1.avif 460w", "/en/static/38ce1c00d0a8590a8c59c0801308869f/7f308/InstarVision2_Layout_1x1.avif 920w", "/en/static/38ce1c00d0a8590a8c59c0801308869f/e3723/InstarVision2_Layout_1x1.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/38ce1c00d0a8590a8c59c0801308869f/a0b58/InstarVision2_Layout_1x1.webp 230w", "/en/static/38ce1c00d0a8590a8c59c0801308869f/bc10c/InstarVision2_Layout_1x1.webp 460w", "/en/static/38ce1c00d0a8590a8c59c0801308869f/966d8/InstarVision2_Layout_1x1.webp 920w", "/en/static/38ce1c00d0a8590a8c59c0801308869f/a9a89/InstarVision2_Layout_1x1.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/38ce1c00d0a8590a8c59c0801308869f/81c8e/InstarVision2_Layout_1x1.png 230w", "/en/static/38ce1c00d0a8590a8c59c0801308869f/08a84/InstarVision2_Layout_1x1.png 460w", "/en/static/38ce1c00d0a8590a8c59c0801308869f/c0255/InstarVision2_Layout_1x1.png 920w", "/en/static/38ce1c00d0a8590a8c59c0801308869f/2bef9/InstarVision2_Layout_1x1.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/38ce1c00d0a8590a8c59c0801308869f/c0255/InstarVision2_Layout_1x1.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "2-x-2-layout",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#2-x-2-layout",
        "aria-label": "2 x 2 layout permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2 x 2 Layout`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/29b8c24da6703eb7e9f84394513662a8/2bef9/InstarVision2_Layout_2x2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqklEQVQozwGfAmD9ALC7v8PP2s7W3tfb3s/R1czO0svNz8TFxL2+vJixx4mqwoqpvoeluoqktoumuI2rwJKuxJ220KuvtLCurQBXXD5mb1l2f3FseXVYam1QYGJRYF1UY11EUlBXYlBXY0xDUEI9Sz5BTUFBTT0+SzxVYlFhcGG8v8DZ2NgARUcpPkAZVFQnVFcnS1YsSE9ARUxAP0s1NT40VV1DW2tCYG1LS1Q/S1g+Ul88c3llW2RNUVw7xsfC4+LjAGhzQ05ZKl5rMlpnLU9gJjxLLSwwMjM5LktSQV9kTF1hRVVjNoCHdoGFdGlpUn2CbW9sXGtsW87OzPHx8QBofDZfeyFfeyNkgSRedidTbhtTbhhYbyJebiuKh3hvbGRkaVWHgHV0bl6HfnqMiol2dXF+fHjo5+X///8Aj5OAfoRwfYZxipR0ipN0iZJ0kZ9yiJRqiZBueoKFXW5xXW1rd31/g4SAmJWPoqKfdn98aHR24uPl/f39AM3V2Nfh49nj59Pf6M7b6cva6cHQ5LvK1MLJzbTG3LTK4r/V6tDi9Oj1//X//97w+8Hf+73b8unu8v38+wBPX0pGWT5KXUlHXEpIYFJFYF9SZ3dXZnBnaFl8iI2wwc7N2OG9ydGpu8eMoK1OYm5HW2RHW2fW2dz///8ATF1PQVQ+Tl5IXWZSaXBZe3trjYiAgXdyVVFNZFJJk313TllcLzg+JSwuHiUlJC0sLDQ3KjY41tna////AHJ0XXZzYYV8aW1tUGlnSXZuQVteMYJ+TVNQQl9IP089MUE6M01IQzEyJyswIjk7MEZEPEFENKWnpcrJygCjlo2NhGtrbEM+SiRHSypwbj9IWyFJVDBITDxTVklNT0teYFReZU9aYkdSXD9aX0xTVkM+RyuLjYipqaoSFz5gXjnzzQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/29b8c24da6703eb7e9f84394513662a8/e4706/InstarVision2_Layout_2x2.avif 230w", "/en/static/29b8c24da6703eb7e9f84394513662a8/d1af7/InstarVision2_Layout_2x2.avif 460w", "/en/static/29b8c24da6703eb7e9f84394513662a8/7f308/InstarVision2_Layout_2x2.avif 920w", "/en/static/29b8c24da6703eb7e9f84394513662a8/e3723/InstarVision2_Layout_2x2.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/29b8c24da6703eb7e9f84394513662a8/a0b58/InstarVision2_Layout_2x2.webp 230w", "/en/static/29b8c24da6703eb7e9f84394513662a8/bc10c/InstarVision2_Layout_2x2.webp 460w", "/en/static/29b8c24da6703eb7e9f84394513662a8/966d8/InstarVision2_Layout_2x2.webp 920w", "/en/static/29b8c24da6703eb7e9f84394513662a8/a9a89/InstarVision2_Layout_2x2.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/29b8c24da6703eb7e9f84394513662a8/81c8e/InstarVision2_Layout_2x2.png 230w", "/en/static/29b8c24da6703eb7e9f84394513662a8/08a84/InstarVision2_Layout_2x2.png 460w", "/en/static/29b8c24da6703eb7e9f84394513662a8/c0255/InstarVision2_Layout_2x2.png 920w", "/en/static/29b8c24da6703eb7e9f84394513662a8/2bef9/InstarVision2_Layout_2x2.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/29b8c24da6703eb7e9f84394513662a8/c0255/InstarVision2_Layout_2x2.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "3-x-3-layout",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#3-x-3-layout",
        "aria-label": "3 x 3 layout permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3 x 3 Layout`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cce0593b114056cb14d71e0ff477b5ea/2bef9/InstarVision2_Layout_3x3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqklEQVQozwGfAmD9ADc3OhocHB4eHh8fHSIhHyMiICMhIBsaGiMfHkpCQFBJRlNLQlFKRVNUV1Jjc2tze3l9gWp1fp+ipLGvrwCksLO3xdDDzdbM0NXEx8u/xMjAwsa4urqrrayYg36FbWSLe3CwkoKejoqsucaquMOImKNmfIq9wsXW1dQAW2BCaG9Yd4FxbXp1WmttU2NjVGJfVmVfRVRSalhSmXpxkYB8n4iBdGFaTUU9Oj4zKzAiMzcpuLq24uHiAERHKD1AGFNUJ1RYJktVK0hOQERLPz9LNDQ9NCMeIioeHSgkIyIgIB8eIRcaGxMXEBMXEhocGLy8u/Pz8wBmcj9MWCdcaS9XYypMXSI5SCgoLC0wNipKUT9ZbG1KYGpIXGFXbHRATlImIxaDfHllYFoXGxDMzM3///8AdYpFbYkxbYgzcY4zbIM2YHwqX3snY3ovans7Zm9MYm5WY2tWcXljT1VDOjokfXZmVU1EIigXysvJ////ADY4IhgdCBsiDScvECoyFCwzFTZAFDQ/FDpAHFRTQ1VWR2ljWWlnYEJDOiYlFoODf11gXwkOBsrLyf///wBJR0syMDIzMjMyMDAkJCwIDx8JDhwJDRoRExwgKTAgKi0nLTAnLC0rLzMwMjYyNDYzNTcrLS3Q0ND///8Aj52UjZ2Wj6CkkaCteHlqWoWlVJXXU43HUYnCiqa+q7rFrbvCrLrCpKmpnJ2ZnaGglJaVdn2C3uDi////AGZuWVpiRWdrTGlqVm1jSZKcg2aJmnubsGyMn2RvZW9vTW5uS3N4S314RYV4U2xvTk5TQkBENKWmo8rKygBtZ1w9Pis9PiY3PR48PTYyNR4oLRAzNCYoLBs2OSU8PR8+PB89QRpHRiZdUT1JRysxNSA0Nh2KioSnpqfPMf14epZxgAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cce0593b114056cb14d71e0ff477b5ea/e4706/InstarVision2_Layout_3x3.avif 230w", "/en/static/cce0593b114056cb14d71e0ff477b5ea/d1af7/InstarVision2_Layout_3x3.avif 460w", "/en/static/cce0593b114056cb14d71e0ff477b5ea/7f308/InstarVision2_Layout_3x3.avif 920w", "/en/static/cce0593b114056cb14d71e0ff477b5ea/e3723/InstarVision2_Layout_3x3.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cce0593b114056cb14d71e0ff477b5ea/a0b58/InstarVision2_Layout_3x3.webp 230w", "/en/static/cce0593b114056cb14d71e0ff477b5ea/bc10c/InstarVision2_Layout_3x3.webp 460w", "/en/static/cce0593b114056cb14d71e0ff477b5ea/966d8/InstarVision2_Layout_3x3.webp 920w", "/en/static/cce0593b114056cb14d71e0ff477b5ea/a9a89/InstarVision2_Layout_3x3.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cce0593b114056cb14d71e0ff477b5ea/81c8e/InstarVision2_Layout_3x3.png 230w", "/en/static/cce0593b114056cb14d71e0ff477b5ea/08a84/InstarVision2_Layout_3x3.png 460w", "/en/static/cce0593b114056cb14d71e0ff477b5ea/c0255/InstarVision2_Layout_3x3.png 920w", "/en/static/cce0593b114056cb14d71e0ff477b5ea/2bef9/InstarVision2_Layout_3x3.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cce0593b114056cb14d71e0ff477b5ea/c0255/InstarVision2_Layout_3x3.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "8-x-8-layout",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#8-x-8-layout",
        "aria-label": "8 x 8 layout permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`8 x 8 Layout`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4e7a49ea7869019a86ae1a20a4211fb6/2bef9/InstarVision2_Layout_8x8.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACcElEQVQozx3P207TYAAA4L6LXnlhjC8ARozIyNihO7Kt7Vq2Hv727+Hvcd3frl3HOg8RXFSiiYnRRLxQNPEFIAGNDwARuQBvjCbEJ2BGn+DLR3gDTtDrHciysKO6UPWA1XP78Vg09DUoMoBhZFZAdQ42ZdPG0Ui1dYSVV69fftjZIeqtuUzuGsMWB6EnG8gd9pHDZxj9BtPjBHEYOZn8XK58fT47P8+EJCsip+vGeG//4OjwkOA5im01WCojwGqFWeHAkttvUTauWeMSXda0cqVwS2KpQq1ese/xHtLNEist7+3vnp2eEYkGUwTXR8gaKIJZrnBXg6GuBaP0xXsU2VbQmURWLHFhL4y23po41J1GuX3ly9fd8/M/hCCza3RRhsDEQZDE08cbvEiZ5poMuK4iGb4vCWynTRZrOccEstxSdWH69NHPX79nsxkxfbY5GPeytZxsKFbfVm2Fh10v8VVfTMY9qEokVcRppAeqHWkIm5La1RxwfPJ9djEjeCNbZhfagLL6GiUKhTYAmq3aQHZlGtxpCou02IAm0D2k9aGGZE52snTn8NvxP7lay+TyN1urGd9TVmj+tvqwCQUcy9DR8+RiobBQry56nkJ1uH7St7x2SXWX1PtHJ6ez2QUxQNBXJdNoiKgKAx8kUxQNoLmcb16GndoAaTqsyhbFgGWSuuRhTsSxPtn68fP/GbQbOl2fpLGTuCrG6cYT10PRyAzHBtsoGXT9bhqZsWeGajTRg8j0A2998/m7j58+H+wTwwdRL1AqDTKKh4rCIMQCjRb41jANndjFkVZZJTHG4/EoTny6TdoeEKRqOkm232z/Bf6UNe8ugTGGAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4e7a49ea7869019a86ae1a20a4211fb6/e4706/InstarVision2_Layout_8x8.avif 230w", "/en/static/4e7a49ea7869019a86ae1a20a4211fb6/d1af7/InstarVision2_Layout_8x8.avif 460w", "/en/static/4e7a49ea7869019a86ae1a20a4211fb6/7f308/InstarVision2_Layout_8x8.avif 920w", "/en/static/4e7a49ea7869019a86ae1a20a4211fb6/e3723/InstarVision2_Layout_8x8.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4e7a49ea7869019a86ae1a20a4211fb6/a0b58/InstarVision2_Layout_8x8.webp 230w", "/en/static/4e7a49ea7869019a86ae1a20a4211fb6/bc10c/InstarVision2_Layout_8x8.webp 460w", "/en/static/4e7a49ea7869019a86ae1a20a4211fb6/966d8/InstarVision2_Layout_8x8.webp 920w", "/en/static/4e7a49ea7869019a86ae1a20a4211fb6/a9a89/InstarVision2_Layout_8x8.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4e7a49ea7869019a86ae1a20a4211fb6/81c8e/InstarVision2_Layout_8x8.png 230w", "/en/static/4e7a49ea7869019a86ae1a20a4211fb6/08a84/InstarVision2_Layout_8x8.png 460w", "/en/static/4e7a49ea7869019a86ae1a20a4211fb6/c0255/InstarVision2_Layout_8x8.png 920w", "/en/static/4e7a49ea7869019a86ae1a20a4211fb6/2bef9/InstarVision2_Layout_8x8.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4e7a49ea7869019a86ae1a20a4211fb6/c0255/InstarVision2_Layout_8x8.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      